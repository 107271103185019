{
  "LANGUAGE_NAME": "Nederlands",
  "MENU": {
    "HOME": "Home",
    "TELEPHONE": "Telefoon",
    "CHAT": "Chatten",
    "SETTINGS": "Instellingen"
  },
  "PAGES": {
    "INFO_PAGE": "Informatiecentrum",
    "GOOD_HABITS": "Goede gewoonten"
  },
  "ASK_SUPPORT": {
    "CONTACT_TEXT": "Bel onze adviseurs",
    "CHAT_TEXT": "Chat met onze adviseurs"
  },
  "NOTIFICATIONS": {
    "TITLE": "Meldingen",
    "SELECT_FREQUENCY": "Selecteer de frequentie",
    "FREQ_NEVER": "Nooit",
    "FREQ_DAILY": "Dagelijks",
    "FREQ_CHOICE": "Kies je dagen",
    "DAYS": "Dagen",
    "TIME": "Tijd"
  },
  "GOOD_HABITS": {
    "INTRO": "Welke goede gewoonten zou je in je dagelijkse leven willen opnemen?",
    "ADD_YOUR_HABIT_TEXT": "Markeer je favorieten met een ster",
    "NEW": "Nieuw",
    "YOU_ARE_ABOUT_TO_REMOVE_A_HABBIT_FROM_FAVORITES": "Wil je deze gewoonte uit je favorieten verwijderen?",
    "YOU_DONT_HAVE_ANY_FAVORITES_YET": "Je hebt nog geen favorieten geselecteerd",
    "GOOD_HABIT_1_TITLE": "Geniet van wat frisse lucht",
    "GOOD_HABIT_1_TEXT": "Maak een korte wandeling",
    "GOOD_HABIT_2_TITLE": "Vind het ritme",
    "GOOD_HABIT_2_TEXT": "Dans - samen of alleen",
    "GOOD_HABIT_3_TITLE": "Leesplezier",
    "GOOD_HABIT_3_TEXT": "lees elke dag/wekelijks een boek",
    "GOOD_HABIT_4_TITLE": "Loskoppelen",
    "GOOD_HABIT_4_TEXT": "Laat je mobiele telefoon buiten je slaapkamer blijven",
    "GOOD_HABIT_5_TITLE": "Verspreid vreugde",
    "GOOD_HABIT_5_TEXT": "Glimlach naar een vreemde",
    "GOOD_HABIT_6_TITLE": "Muziek",
    "GOOD_HABIT_6_TEXT": "Neem de tijd om naar mijn favoriete muzikant te luisteren",
    "GOOD_HABIT_7_TITLE": "Geef uw dag de revue",
    "GOOD_HABIT_7_TEXT": "Denk aan de goede momenten van je dag en wees er dankbaar voor",
    "GOOD_HABIT_8_TITLE": "Flower-power",
    "GOOD_HABIT_8_TEXT": "Koop bloemen voor het weekend",
    "GOOD_HABIT_9_TITLE": "Vertragen",
    "GOOD_HABIT_9_TEXT": "Neem wat extra tijd en geniet",
    "GOOD_HABIT_10_TITLE": "Geniet van wat frisse lucht",
    "GOOD_HABIT_10_TEXT": "Breng wat tijd door in de natuur",
    "GOOD_HABIT_11_TITLE": "Trap",
    "GOOD_HABIT_11_TEXT": "Meer trappen, minder liften",
    "GOOD_HABIT_12_TITLE": "Voel de grond",
    "GOOD_HABIT_12_TEXT": "Loop op blote voeten",
    "GOOD_HABIT_13_TITLE": "Verspreid vreugde",
    "GOOD_HABIT_13_TEXT": "Zeg wat ik leuk vind",
    "GOOD_HABIT_14_TITLE": "De weg naar het hart gaat door de maag",
    "GOOD_HABIT_14_TEXT": "Koken met anderen",
    "GOOD_HABIT_15_TITLE": "Plezier in het spelen",
    "GOOD_HABIT_15_TEXT": "Speel opnieuw een spel",
    "GOOD_HABIT_16_TITLE": "Bekijk smaken",
    "GOOD_HABIT_16_TEXT": "Nieuwe theesoorten uitproberen",
    "GOOD_HABIT_17_TITLE": "Vers bedekt",
    "GOOD_HABIT_17_TEXT": "Was je beddengoed, hang het buiten op, slaap er 's avonds in - heerlijk!",
    "GOOD_HABIT_18_TITLE": "Goed doen",
    "GOOD_HABIT_18_TEXT": "Doe elke dag een goede daad",
    "GOOD_HABIT_19_TITLE": "Dicht bij de tenen",
    "GOOD_HABIT_19_TEXT": "Stretchen of yoga in de ochtend",
    "GOOD_HABIT_20_TITLE": "Beweging",
    "GOOD_HABIT_20_TEXT": "Loop elke dag een blokje om",
    "GOOD_HABIT_21_TITLE": "Smaken uitproberen",
    "GOOD_HABIT_21_TEXT": "Mindful eten: hoe ontvouwt smaak zich?",
    "GOOD_HABIT_22_TITLE": "Digitale detox",
    "GOOD_HABIT_22_TEXT": "Cre\u00eber time-outs van elektronica",
    "GOOD_HABIT_23_TITLE": "Voel je mooi",
    "GOOD_HABIT_23_TEXT": "Draag mooie kleding",
    "GOOD_HABIT_24_TITLE": "Opruimen",
    "GOOD_HABIT_24_TEXT": "Haal het onnodige uit het dagelijks leven",
    "GOOD_HABIT_25_TITLE": "Geniet van kunst",
    "GOOD_HABIT_25_TEXT": "Bezoek online een concert of museum"
  },
  "LOGIN": {
    "NEXT": "Volgende",
    "INTRO_TEXT": "Code",
    "NAME_FIELD": "Naam (bijnaam mag ook)",
    "REQUIRED_FIELD": "Vereist",
    "NAME_OR_PSEUDONYM_REQUIRED": "Meld je aan met een gebruikersnaam.",
    "CODE_NOT_CORRECT": "De ingevoerde code is onjuist.",
    "AGE_GROUP": "Leeftijd",
    "YEARS_OLD": "jaar oud",
    "I_AGREE_TERMS": "Ik ga akkoord met {{terms}}",
    "TERMS_TEXT": "de gebruiksvoorwaarden",
    "LOGIN_CONSENT_LINK_TEXT": "het privacybeleid",
    "LOGIN_CONSENT": "Door in te loggen stem je in met de verwerking van gegevens volgens {{data}}.",
    "LOGIN": "Log in"
  },
  "PIN_MODAL": {
    "FIRST_TIME": "Voer een PIN in om je gegevens te beschermen",
    "FIRST_TIME_DONT_LOSE": "Bewaar je PIN goed. Je hebt deze nodig om in de app te komen. Raak je je PIN kwijt? Dan kun je je account niet meer gebruiken en moet je opnieuw beginnen.",
    "ENTER_PIN": "Voer je PIN in",
    "CHOOSE_PIN": "Kies een PIN",
    "CONFIRM_PIN": "Bevestig PIN",
    "PIN_SET_SUCCESS": "Je PIN is ingesteld",
    "PIN_CHANGE_SUCCESS": "Je PIN is gewijzigd",
    "PIN_CHANGE": "PIN wijzigen",
    "CHOOSE_NEW_PIN": "Kies een nieuwe PIN",
    "CONFIRM_NEW_PIN": "Bevestig nieuwe PIN",
    "PIN": "PIN",
    "ERROR_WRONG_PIN": "De ingevoerde PIN is onjuist",
    "ERROR_PIN_NOT_MATCH": "De PIN's komen niet overeen",
    "ERROR_SAME_NUMBERS": "Om veiligheidsredenen mag je PIN niet uit hetzelfde nummer bestaan",
    "ERROR_SIMPLE_PIN": "Om veiligheidsredenen mag je PIN niet eenvoudig zijn",
    "FORGOT_PIN": "PIN vergeten?",
    "FORGOT_PIN_TEXT": "We kunnen je PIN niet herstellen. Als je je PIN bent vergeten, is je account niet meer toegankelijk. Je kunt je account opnieuw instellen door je aan te melden met een nieuwe code. Je gegevens zijn dan niet meer beschikbaar. Wil je doorgaan?",
    "FORGOT_PIN_BUTTON_CANCEL": "Nee, annuleren",
    "FORGOT_PIN_BUTTON_YES_RESET": "Ja, resetten",
    "BIOMETRIC_VERIFICATION_SETUP_FACEID": "Wil je Face ID gebruiken om in te loggen?",
    "BIOMETRIC_VERIFICATION_FACEID_FAILED": "Face ID verificatie is mislukt, je gezicht werd niet herkend.",
    "BIOMETRIC_VERIFICATION_REASON": "Verificatie",
    "BIOMETRIC_VERIFICATION_TITLE": "Verificatie",
    "BIOMETRIC_VERIFICATION_SUBTITLE": "Verificatie",
    "BIOMETRIC_VERIFICATION_DESCRIPTION": "Verificatie"
  },
  "HOME": {
    "CODE_INVALID_TEXT": "Je sessie is be\u00ebindigd omdat je code is verlopen.",
    "BACKGROUND_ARTICLES": "artikelen",
    "BACKGROUND_ARTICLES_SUB": "Informatie en inspiratie",
    "GOOD_HABITS": "Goede gewoontes",
    "REFLECTION": "Hoe gaat het met je?",
    "GOALS": "Doelen",
    "WHAT_IS_EAP": "Hoe we jou kunnen helpen",
    "QUICK_CHECKLISTS": "Controle lijsten",
    "QUICK_CHAT": "Chatten",
    "QUICK_TELEPHONE": "Telefoon",
    "QUICK_RELAX": "Ontspannen",
    "QUICK_PODCASTS": "Podcasts",
    "QUICK_VIDEOS": "Videos",
    "QUICK_APPOINTMENTS": "Afspraak",
    "TOPICAL": "Nieuws",
    "ARTICLE_OVERVIEW": "Overzicht",
    "ARTICLE_OVERVIEW_SUBTITLE": "Alle",
    "CONTACT": "Contact",
    "UNWANTED_BEHAVIOR": "Ongewenst gedrag",
    "SHOW_ALL_CATEGORIES": "Bekijk alle artikelen",
    "ARTICLE_OF_THE_WEEK": "Artikel van de week",
    "TESTS": "Testen"
  },
  "INFO_PAGES": {
    "THEMES_INFO": "Informatie en inspiratie vind je hier.",
    "OTHER_THEMES": "Andere onderwerpen",
    "NEED_SUPPORT": "Kunnen we je helpen?",
    "ARTICLES": "artikelen"
  },
  "INFO_PAGE": {
    "TITLE": "Informatiecentrum",
    "READING_TIME": "{{minuten}} min. Leestijd",
    "READING_TIME_ALT": "Leestijd: {{minutes}} min."
  },
  "CONTACT": {
    "TITLE": "Telefoon",
    "CONTENT": "Bel ons als u iemand wilt spreken. In geval van nood zijn wij 24/7 bereikbaar.",
    "CALL": "Telefoongesprek",
    "CALL_DESCRIPTION": "Neem contact op met onze adviseurs",
    "TELEPHONE": "Telefoon",
    "TELEPHONE_SAVE_OR_SHARE": "Telefoonnummer *",
    "TELEPHONE_NUMBER_1": "0800 1234567",
    "TELEPHONE_NUMBER_1_ADDITION": "(Duitsland)",
    "TELEPHONE_NUMBER_2": "00800 1234567",
    "TELEPHONE_NUMBER_2_ADDITION": "(Oostenrijk)",
    "TELEPHONE_NUMBER_3_TITLE": "vanuit het buitenland **",
    "TELEPHONE_NUMBER_3": "+31 123 456 78",
    "TELEPHONE_NUMBER_3_ADDITION": "",
    "ADDITIONAL_INFO_1": "* gratis",
    "ADDITIONAL_INFO_2": "** er ontstaan mogelijk extra kosten",
    "CHAT": "Chatten"
  },
  "SETTINGS": {
    "TITLE": "Instellingen",
    "LANGUAGE": "Taal",
    "NOTIFICATIONS": "Reflectie: notificaties",
    "FEEDBACK": "Feedback op de app",
    "PRIVACY_POLICY": "Privacybeleid",
    "USER_CONDITIONS": "Gebruiksvoorwaarden",
    "ABOUT_OS_LICENSES": "Over / Open Source-licenties",
    "REDO_ONBOARDING": "Bekijk introductie opnieuw",
    "NAME_TOO_SHORT": "Je naam moet minimaal 1 teken bevatten"
  },
  "CHAT": {
    "CHAT_CONSENT": "In geval van nood, bel 112 of de neem telefonisch contact op. ",
    "I_UNDERSTAND": "Akkoord",
    "DECLINE": "Weiger",
    "CONSULTANT_TEAM": "Onze adviseurs",
    "CHAT_INTRO": "Stuur ons een bericht zodat we je kunnen helpen.",
    "USER_HAS_UNSIGNED_CHATS": "Onze chat is momenteel niet live. Laat dan een bericht achter, onze adviseurs nemen binnen de volgende werkdag contact met je op. Neem in geval van nood telefonisch contact met ons op of bel 112.",
    "NOT_ASSIGNED": "Adviseur van dienst",
    "NEW_CHAT": "Nieuw gesprek",
    "INPUT_PLACEHOLDER": "Type...",
    "INPUT_PLACEHOLDER_CONNECTING": "Een beveiligde verbinding opzetten...",
    "CURRENT_CHAT": "Huidig gesprek",
    "YOU_ARE_SPEAKING_WITH": "Je praat met"
  },
  "AUDIO_PLAYER": {
    "DOWNLOADING": "downloaden"
  },
  "FEEDBACK": {
    "TITLE": "Feedback op de app",
    "INPUT_PLACEHOLDER": "Typen...",
    "INTRO": "We verbeteren onze app continu voor jou. Als je feedback hebt over de app, kun je deze hier indienen. Dit formulier is niet bedoeld voor het delen van persoonlijke informatie.",
    "NAME": "Naam",
    "NAME_REQUIRED": "Voer een naam in",
    "EMAIL": "e-mailadres",
    "EMAIL_INVALID": "Het e-mailadres is niet correct",
    "EMAIL_REQUIRED": "Voer een e-mailadres in zodat we op je melding kunnen reageren",
    "MESSAGE": "Bericht",
    "MESSAGE_REQUIRED": "Voer een bericht in",
    "SUBMIT_SUCCESS": "Je feedback is verzonden",
    "SEND": "Versturen"
  },
  "REFLECTION_INTRO": {
    "TITLE": "Hoe gaat het met jou?",
    "INTRO_TITLE": "Reflectie",
    "INTRO_TEXT": "Hier kun je een dagboek bijhouden over hoe je je voelt, wat je energie kost en wat je energie geeft."
  },
  "REFLECTION": {
    "TITLE": "Hoe gaat het met jou?",
    "TITLE_WORK": "Hoe gaat het met jou?",
    "TITLE_SPARE_TIME": "Hoe gaat het met jou?",
    "TITLE_INSIGHTS": "Insights",
    "TITLE_REMINDERS": "Herinneringen",
    "TITLE_SETTINGS": "Instellingen: reflectie",
    "HOW_DO_YOU_FEEL_TODAY": "",
    "SELECT_YOUR_MOOD": "Hoe voel je je vandaag?",
    "DESCRIBE_YOUR_FEELINGS": "",
    "FEELINGS_WORK": "Welke factoren op het werk zijn van invloed op je energieniveau?",
    "FEELINGS_SPARE_TIME": "Welke factoren in je vrije tijd zijn van invloed op je energieniveau?",
    "ADVICE": "Advies",
    "FEELINGS_INSIGHTS": "Gevoelens",
    "WORK_INSIGHTS": "Werk",
    "SPARE_TIME_INSIGHTS": "Vrije tijd",
    "SHARE_TITLE": "Deel jouw reflectie",
    "SHARE_TEXT": "Wil je overleg? Deel je resultaten met onze adviseurs en ontwikkel nieuwe verfrissende strategie\u00ebn.",
    "SHARE_BUTTON": "Delen",
    "SHARE_PRIVACY_INFO": "Privacybeleid",
    "SETTINGS_INTRO": "Cre\u00eber meer innerlijke rust met meer kennis van hoe je over dingen denkt.",
    "SETTINGS_INFO_TITLE": "Leer meer over jezelf!",
    "SETTINGS_INFO_TEXT": "Houd gedurende een langere periode bij hoe u zich voelt.",
    "SETTINGS_REMINDER_TITLE": "Wil je een herinnering instellen voor regelmatige reflectie?",
    "SETTINGS_REMINDER_FREQUENCY": "Frequentie",
    "SETTINGS_REMINDER_DAYS": "Dagen",
    "SETTINGS_REMINDER_TIME_OF_DAY": "Tijd van de dag",
    "SETTINGS_REMINDER_SELECT_TIME": "Selecteer tijd",
    "SETTINGS_REMINDER_FREQUENCY_OFF": "Nooit",
    "SETTINGS_REMINDER_FREQUENCY_DAILY": "Dagelijks",
    "SETTINGS_REMINDER_FREQUENCY_WEEKLY": "Wekelijks",
    "SETTINGS_REMINDER_FREQUENCY_TWICE_A_WEEK": "Twee keer per week",
    "SETTINGS_REMINDER_FREQUENCY_MONTHLY": "Maandelijks",
    "SETTINGS_REMINDER_FREQUENCY_TWICE_A_MONTH": "Twee keer per maand",
    "SETTINGS_SAVE": "Sla mijn instellingen op",
    "FEELINGS_TOP": "Top {{NUM}} gevoelens",
    "WORK_TOP": "Top {{NUM}} energie op het werk",
    "SPARE_TIME_TOP": "Top {{NUM}} energie in vrije tijd",
    "POSITIVE": "Positief",
    "NEGATIVE": "Negatief",
    "ENERGY_TAKERS": "Energievreters",
    "ENERGY_GIVERS": "Energie gevers",
    "ADD_WORD": "Woord toevoegen"
  },
  "INSIGHTS": {
    "PAGE_TITLE": "Insights",
    "TITLE": "Door deze tool te gebruiken kun je meer inzicht krijgen in hoe jij je voelt over zaken op het werk en in je priv\u00e9leven."
  },
  "INSIGHTS_SHARE": {
    "TITLE": "Deel jouw inzichten met onze counselors, als je wilt praten over je resultaten of als je verandering in je dagelijks leven wilt brengen.",
    "TEXT": "",
    "MESSAGE_PLACEHOLDER": "Hoe kunnen we jou helpen?",
    "SEND_TO_COUNSELOR": "Versturen",
    "REFLECTION": "Reflectie",
    "LAST_WEEK": "Vorige week",
    "LAST_MONTH": "Vorige maand"
  },
  "MOODS": {
    "VERY_BAD": "Heel slecht",
    "BAD": "Slecht",
    "MODERATE": "Gematigd",
    "GOOD": "Goed",
    "VERY_GOOD": "Erg goed"
  },
  "DAYS": {
    "MONDAY": "Maandag",
    "TUESDAY": "Dinsdag",
    "WEDNESDAY": "Woensdag",
    "THURSDAY": "Donderdag",
    "FRIDAY": "Vrijdag",
    "SATURDAY": "Zaterdag",
    "SUNDAY": "Zondag"
  },
  "LOADING": "Bezig met laden...",
  "BACK": "Terug",
  "POSSIBLY_NO_INTERNET_AVAILABLE": "Er kon geen verbinding met de server tot stand worden gebracht. Controleer je internetverbinding of probeer het later opnieuw.",
  "NAME_OR_PSEUDONYM": "Gebruikersnaam",
  "GENDER": "Gender",
  "GENDER_MALE_CHAR": "m",
  "GENDER_FEMALE_CHAR": "v",
  "GENDER_DIVERSE_CHAR": "anders",
  "GENDER_MALE": "mannelijk",
  "GENDER_FEMALE": "vrouwelijk",
  "GENDER_DIVERSE": "anders",
  "BUTTON_OK": "OK",
  "BUTTON_SAVE": "Opslaan",
  "BUTTON_DONE": "Klaar",
  "BUTTON_CANCEL": "Annuleren",
  "BUTTON_CLOSE": "Sluiten",
  "BUTTON_PREVIOUS": "Vorige",
  "BUTTON_NEXT": "Volgende",
  "BUTTON_ADD": "toevoegen",
  "BUTTON_NEW_WORD": "nieuw woord",
  "ARE_YOU_SURE": "Weet je het zeker?",
  "LOGOUT": "Uitloggen",
  "LOGOUT_TEXT": "Door uit te loggen worden uw in de app opgeslagen gegevens verwijderd.",
  "SETTINGS_SAVED": "Je instellingen zijn opgeslagen",
  "OPEN": "Open",
  "FEELINGS_POS": {
    "OPTIMISTIC": "optimistisch",
    "TRUSTING": "vertrouwend",
    "PEACEFUL": "vredevol",
    "POWERFUL": "krachtig",
    "ACCEPTED": "geaccepteerd",
    "PROUD": "trots",
    "CONTENT": "inhoud",
    "PLAYFUL": "speels",
    "ENERGETIC": "energiek",
    "CONFIDENT": "vol vertrouwen",
    "JOYFUL": "blij",
    "PASSIONATE": "gepassioneerd",
    "LOVED": "geliefd",
    "EAGER": "gretig",
    "RELAXED": "ontspannen",
    "MOTIVATED": "gemotiveerd",
    "HAPPY": "Vrolijk",
    "GRATEFUL": "dankbaar",
    "INSPIRED": "ge\u00efnspireerd",
    "EXCITED": "opgewonden",
    "NOTICED": "merkte op",
    "MINDFUL": "opmerkzaam",
    "CALM": "kalm",
    "RELIEVED": "opgelucht"
  },
  "FEELINGS_NEG": {
    "STRESSED": "gestrest",
    "TIRED": "moe",
    "CONFUSED": "verward",
    "INSECURE": "onzeker",
    "FRUSTRATED": "gefrustreerd",
    "CRITICAL": "kritisch",
    "DISAPPOINTED": "teleurgesteld",
    "HURT": "pijn doen",
    "GUILTY": "schuldig",
    "DEPRESSED": "depressief",
    "LONELY": "alleen",
    "WORRIED": "bezorgd",
    "EMBARRASSED": "gegeneerd",
    "AFRAID": "bang",
    "SAD": "verdrietig",
    "ANXIOUS": "gespannen",
    "UNHAPPY": "ongelukkig",
    "BORED": "verveeld",
    "EXHAUSTED": "uitgeput",
    "PANICKED": "in paniek",
    "ANGRY": "boos",
    "ANNOYED": "verveeld",
    "ASHAMED": "beschaamd",
    "JEALOUS": "jaloers"
  },
  "WORK_POS": {
    "TEAMWORK": "teamwerk",
    "GETTING_COMPLIMENTS": "complimenten krijgen",
    "COFFEE_WITH_COLLEAGUES": "koffie met collega's",
    "SUPPORT_BY_MANAGER": "ondersteuning door beheerder",
    "SUPPORT_BY_COLLEAGUES": "ondersteuning door collega's",
    "ACKNOWLEDGMENT": "erkenning",
    "HAVING_RESPONSIBILITY": "verantwoordelijkheid hebben",
    "JOB_SECURITY": "baangarantie",
    "LIVELY_DISCUSSIONS": "levendige discussies",
    "A_FULL_SCHEDULE": "een vol schema",
    "CONTACT_WITH_CLIENTS": "contact met opdrachtgevers",
    "ENERGETIC_MEETINGS": "energieke bijeenkomsten",
    "SOLVING_PROBLEMS": "problemen oplossen",
    "FINDING_CREATIVE_SOLUTIONS": "creatieve oplossingen vinden",
    "GIVING_PRESENTATIONS": "presentaties geven",
    "LISTENING_TO_PRESENTATIONS": "luisteren naar presentaties",
    "DELIVERING_QUALITY": "kwaliteit leveren",
    "PREVENTING_ERRORS": "voorkomen van fouten",
    "STRUCTURED_WORK": "gestructureerd werken",
    "WORKING_ACCORDING_TO_PLAN": "werken volgens plan",
    "HAVING_OVERVIEW": "overzicht hebben",
    "CELEBRATING_SUCCESS": "succes vieren",
    "A_SHORT_BREAK_OUTSIDE": "een korte pauze buiten"
  },
  "WORK_NEG": {
    "TOUGH_MEETINGS": "zware vergaderingen",
    "BORING_PRESENTATIONS": "saaie presentaties",
    "TOUGH_COMMUNICATION": "moeilijke communicatie",
    "GOAL_UNCLARITY": "doel onduidelijkheid",
    "TIME_PRESSURE": "tijdsdruk",
    "OVERTIME": "na een tijdje",
    "FEAR_OF_FAILURE": "faalangst",
    "DISRUPTION": "ontregeling",
    "LACK_OF_CONTROL": "gebrek aan controle",
    "MUCH_RESPONSIBILITY": "veel verantwoordelijkheid",
    "JOB_INSECURITY": "werkonzekerheid",
    "SHIFT_WORK": "ploegendienst",
    "REMOTEWORK": "afstandswerk",
    "NOISE": "lawaai",
    "DISCUSSIONS": "discussies",
    "MULTITASKING": "multitasking",
    "CONFLICTS": "conflicten",
    "ILLNESS": "ziekte",
    "BEING_UNINFORMED": "onwetend zijn",
    "TRAFFIC_JAM": "verkeersopstopping",
    "HUSTLE": "drukte",
    "HOMESICKNESS": "heimwee",
    "BOREDOM": "verveling",
    "LACK_OF_APPRECIATION": "gebrek aan waardering"
  },
  "SPARE_TIME_POS": {
    "MEDITATION": "meditatie",
    "PLAYING_GAMES": "spelletjes spelen",
    "BEING_ALONE": "alleen zijn",
    "READING": "lezing",
    "COOKING": "koken",
    "PLAYING_MUSIC": "muziek afspelen",
    "SPORTS": "sport-",
    "SHOPPING": "winkelen",
    "ACKNOWLEDGMENT": "erkenning",
    "DISCUSSIONS": "discussies",
    "LOAFING": "luieren",
    "PRIVACY": "privacy",
    "MEETING_FRIENDS": "vrienden ontmoeten",
    "MEETING_FAMILY": "familie ontmoeten",
    "GOING_OUTSIDE": "naar buiten gaan",
    "PLAYING_WITH_THE_KIDS": "spelen met de kinderen",
    "WATCHING_TV": "tv kijken",
    "READING_A_BOOK": "een boek lezen",
    "GARDENING": "tuinieren",
    "A_SHORT_WALK": "een korte wandeling",
    "FEELING_THE_WIND": "de wind voelen",
    "FEELING_THE_SUN": "de zon voelen",
    "GOING_TO_A_CONCERT": "naar een concert gaan",
    "FESTIVALS": "festivals",
    "THEATER": "theater"
  },
  "SPARE_TIME_NEG": {
    "FRIENDS": "vrienden",
    "FAMILY": "familie",
    "BEING_ALONE": "alleen zijn",
    "SLEEP_DEPRIVATION": "slaaptekort",
    "COOKING": "koken",
    "MOVEMENT": "beweging",
    "GROCERY_SHOPPING": "boodschappen doen",
    "NOISE": "lawaai",
    "DISCUSSIONS": "discussies",
    "MULTITASKING": "multitasking",
    "CONFLICTS": "conflicten",
    "ILLNESS": "ziekte",
    "PERFECTIONISM": "perfectionisme",
    "CONCERNS": "zorgen",
    "TRAFFIC_JAM": "verkeersopstopping",
    "BEING_UNINFORMED": "onwetend zijn",
    "SELF_DOUBT": "twijfel aan jezelf",
    "DEBT": "schuld",
    "HUSTLE": "drukte",
    "HOMESICKNESS": "heimwee",
    "ANXIETY": "spanning",
    "HEALTH_ISSUES": "gezondheidsproblemen",
    "FAMILY_CARE": "familie zorg",
    "TIDYING_UP": "opruimen"
  },
  "GOALS_INTRO": {
    "TITLE": "Doelen",
    "INTRO_TITLE": "Stel je doelen",
    "INTRO_TEXT": "Hier kun je je doelen instellen, toevoegen en volgen.",
    "START_BUTTON": "Laten we gaan!",
    "SEE_YOUR_INSIGHTS": "Uw inzichten"
  },
  "GOALS": {
    "TITLE": "Doelen",
    "TITLE_SETTINGS": "Instellingen: doelen",
    "TEXT": "Welke doelen zou je willen bereiken?",
    "SELECT_YOUR_GOALS": "Selecteer uw doelen",
    "ADD_GOAL": "Doel",
    "TRACK_YOUR_GOALS": "Volg jouw doelen",
    "GOAL_TITLE_NUMBER": "Doel",
    "EDIT": "Bewerking",
    "ACTIVE_GOALS": "Actieve doelen",
    "OVERVIEW_GOALS": "Overzicht",
    "OVERVIEW_TEXT": "Wil je je doelen wijzigen? Je kunt ze hier bewerken.",
    "SHARE_TITLE": "Deel je doelen",
    "SHARE_TEXT": "Wil je overleg? Deel je resultaten met onze adviseurs en ontwikkel nieuwe verfrissende strategie\u00ebn.",
    "SHARE_BUTTON": "Deel resultaten met de adviseur",
    "SHARE_PRIVACY_INFO": "Privacybeleid",
    "INSIGHTS_DAYS_WITH_COMPLETED_GOALS": "Jouw vooruitgang",
    "LAST_21_DAYS": "Afgelopen 21 dagen"
  },
  "EMOJI": {
    "CHOOSE_EMOJI": "Kies Emoji",
    "CHOOSE_YOUR_EMOJI": "Kies een emoji"
  },
  "UNWANTED_BEHAVIOR": {
    "INTRO_TEXT": "Zie je discriminatie, crimineel gedrag, pesten of ander gedrag op het werk dat niet zou moeten bestaan?",
    "INTRO_REPORT_TEXT": "Wil je dit met ons delen?",
    "START_BUTTON": "Volgende",
    "TITLE": "Ongewenst gedrag",
    "DESCRIBE": "Beschrijf de situatie die je wilt delen.",
    "WRITE_HERE": "typen...",
    "MAKE_REPORT": "Rapport",
    "OPTION_REPORT_COMPANY": "Breng mijn werkgever zo snel mogelijk op de hoogte.",
    "OPTION_REPORT_ANONYMOUS": "Mijn melding is niet urgent. Gelieve deze anoniem op te nemen in het jaarverslag.",
    "BEFORE_SEND_TEXT": "Door op verzenden te klikken, ga ik akkoord met het opslaan van mijn gegevens voor de hierboven genoemde doeleinden.",
    "INFO_POPUP_TITLE": "Informatie",
    "INFO_POPUP_TEXT": "Gerapporteerde meldingen sturen we twee keer per jaar door naar jouw organisatie. In dringende gevallen is onmiddellijke verzending mogelijk met jouw toestemming.",
    "SEND_POPUP_TITLE": "Weet je zeker dat je het wilt verzenden?",
    "SEND_POPUP_TEXT": "Ons team helpt je graag verder. Alle informatie wordt vertrouwelijk behandeld.",
    "SEND_POPUP_BACK": "Nee, ga terug",
    "SEND_POPUP_SEND": "Ja, rapport verzenden"
  },
  "CONFIRM_SCREEN_SHARE": {
    "ARE_YOU_SURE": "Deel jouw overzicht met onze adviseurs",
    "TEXT": "Weet je zeker dat je je overzicht wilt delen?",
    "CANCEL": "Nee, ga terug",
    "CONFIRM": "Ja, stuur resultaten"
  },
  "RANGE": {
    "LAST_WEEK": "Vorige week",
    "THIS_WEEK": "Deze week",
    "DAY_LETTER": "D",
    "WEEK_LETTER": "W",
    "MONTH_LETTER": "M",
    "YEAR_LETTER": "J"
  },
  "TESTS_INTRO": {
    "TITLE": "Tests",
    "INTRO_TEXT": "Hier vind je verschillende zelftesten. Deze testen zijn bedoeld als eerste screening om inzicht te krijgen voor eigen gebruik, niet te beschouwen als een diagnose. Uw gegevens worden anoniem verwerkt en zijn niet tot u persoonlijk te herleiden.",
    "INFO_POPUP_TEXT": "Jouw individuele testgegevens zijn alleen voor eigen gebruik. Alleen als je daarvoor kiest, kun je de gegevens delen met een vertrouwenspersoon. Adviseurs zijn gebonden aan strikte geheimhoudingsregels. Wij voegen de ingevulde gegevens niet toe aan een persoonlijk profiel, noch aan de administratie van de organisatie waar je werkt. Alleen om de vragenlijsten te verbeteren bewaren we testresultaten in \u00e9\u00e9n grote database. Deze gegevens zijn op geen enkele manier te herleiden tot u als gebruiker. In dit bestand voegen we alleen leeftijdscategorie en geslacht toe.",
    "START_BUTTON": "Starten"
  },
  "TESTS": {
    "TITLE": "Tests",
    "TEXT": "",
    "TITLE_NOT_COMPLETED": "In behandeling",
    "TITLE_COMPLETED": "Voltooid",
    "QUESTIONS": "Vragen",
    "RESULTS": "Test resultaten",
    "RESULT_SCORE": "je score is",
    "ALL_TESTS_DONE": "Alle testen zijn afgerond",
    "NO_TESTS_DONE": "Er zijn nog geen tests voltooid",
    "SHARE_TITLE": "Deel je testresultaten",
    "SHARE_TEXT": "Wil je overleg? Deel jouw resultaten met onze adviseurs en ontwikkel nieuwe verfrissende strategie\u00ebn.",
    "SHARE_BUTTON": "Deel",
    "SHARE_PRIVACY_INFO": "Privacybeleid"
  }
}
