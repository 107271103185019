import { Component, Inject } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../environments/environment";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-root",
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.document.body.classList.add(environment.app);

    this.translate.addLangs(environment.languages);
    this.translate.setDefaultLang(environment.languages[0]);
    this.matIconRegistry.addSvgIcon(
      `chats_pending`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `../assets/icons/chats-pending.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `my_chats`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `../assets/icons/my-chats.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `new_chats`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `../assets/icons/new-chats.svg`
      )
    );
    this.matIconRegistry.addSvgIcon(
      `away_message`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `../assets/icons/away-message.svg`
      )
    );
  }
}
