{
  "BUTTON_YES": "Ja",
  "BUTTON_NO": "Nein",
  "BUTTON_OK": "Ok",
  "BUTTON_CANCEL": "Abbrechen",
  "BUTTON_SAVE": "Speichern",
  "BUTTON_SEND": "Senden",
  "TOOLTIPS": {
    "LOGOUT": "Ausloggen",
    "ARCHIVE_CHAT": "Archivieren",
    "CHAT_INFORMATION": "Informationen",
    "EXPORT_CHAT_AS_PDF": "Chat als PDF exportieren",
    "EXPORT_INSIGHT_AS_PDF": "Insights als PDF exportieren"
  },
  "LOGIN": {
    "TITLE": "Einloggen in Ihr {{TYPE}} Konto",
    "EMAIL": "E-Mail",
    "PASSWORD": "Passwort",
    "REMEMBER": "Angemeldet bleiben",
    "ERROR": {
      "INACTIVITY": "Sie wurden wegen Inaktivit\u00e4t automatisch abgemeldet.",
      "MISMATCH": "Sie wurden wegen Inaktivit\u00e4t automatisch abgemeldet.",
      "CREDENTIALSNOTFOUND": "Die eingegebenen Zugangsdaten sind nicht korrekt.",
      "UNKNOWN": "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",
      "2FA_INVALID": "Der eingegebene Code ist nicht korrekt.",
      "NO2FA": "2FA ist nicht aktiviert."
    }
  },
  "WEBCHAT": {
    "WELCOME_BACK": "Willkommen zur\u00fcck in unserem Chat. Geben Sie Ihre Pin ein und gelangen Sie zur\u00fcck in Ihre pers\u00f6nliche Chatumgebung.",
    "WELCOME": "Herzlich Willkommen! Chatten Sie hier zwischen 8-18 Uhr mit unserem Beratungsteam oder hinterlassen Sie eine Nachricht, die am folgenden Werktag beantwortet wird. Der Chat wird streng vertraulich behandelt. Wegen unserer Dokumentationspflicht werden die Chatverl\u00e4ufe bei Stimulus unter Beachtung des Datenschutzes gespeichert.",
    "EMERGENCY": "Wenden Sie sich in Notf\u00e4llen telefonisch an uns oder w\u00e4hlen Sie die 112.",
    "CHOOSE_USERNAME": "Nutzernamen w\u00e4hlen",
    "FILL_IN_PIN": "Pin eingeben",
    "FORGOT_PIN": "Pin vergessen? Beginnen Sie ein neues Gespr\u00e4ch",
    "I_ACCEPT_THE_TERMS": "Ich akzeptiere die ",
    "TERMS": "Nutzungsbedingungen",
    "CHOOSE_PIN": "Pin w\u00e4hlen, um den Chat zu sichern",
    "OUR_TEAM": "Unser Beratungsteam",
    "OUR_TEAM_TEXT": "Schicken Sie uns eine Nachricht, damit wir Sie bei Ihrem Anliegen unterst\u00fctzen k\u00f6nnen.",
    "COUNSELOR": "Berater",
    "BUTTON_LOGIN": "Login",
    "LOGOUT": "Gespr\u00e4ch sperren",
    "LOADING": "Wird geladen..",
    "REFLECTION": "Reflexion",
    "REFLECTION_SPARE_TIME": "Freizeit",
    "REFLECTION_WORK": "Arbeit",
    "REFLECTION_FEELINGS": "Gef\u00fchle",
    "REFLECTION_MOODS": "Stimmungen",
    "SEND": "Senden",
    "ANSWER": "Antworten",
    "ESTABLISHING_SECURE_CONNECTION": "Sichere Verbindung herstellen",
    "UNWANTED_BEHAVIOUR": "Unerw\u00fcnschtes Verhalten",
    "FORGOT_PIN_TITLE": "Zugang zum Chat",
    "FORGOT_PIN_TEXT": "ie haben Ihre PIN vergessen. Ohne diese PIN können Sie nicht mehr auf Ihr voriges Gespräch zugreifen. Sie können gerne ein neues Gespräch beginnen. Möchten Sie das tun?"
  },
  "STATUS": {
    "AVAILABLE": "Verf\u00fcgbar",
    "UNAVAILABLE": "Nicht verf\u00fcgbar"
  },
  "CHAT": {
    "WELCOME": "Willkommen",
    "PENDING": "\u00dcbergabe",
    "NEW_CHATS": "Neue Chats",
    "SHOW_ARCHIVE": "Chat-Archiv anzeigen",
    "ARCHIVE": "Archiv",
    "COUNSELORS": "BeraterInnen",
    "COUNSELORS_IN_THIS_CHAT": "BeraterInnen in diesem Chat",
    "NONE": "Keine",
    "CHAT_INFORMATION": "Informationen",
    "ENABLE_PUSH_NOTIFICATIONS": "Benachrichtigungen einschalten",
    "NO_CHATS": "Keine Gespr\u00e4che",
    "NO_NAME": "Kein Name",
    "MY_CHATS": "Meine Gespr\u00e4che",
    "RECONNECTING": "Sichere Verbindung herstellen",
    "TYPE_MESSAGE": "Antworten",
    "SAVE": "Speichern",
    "ENCRYPTION": {
      "CHANGE_ENCRYPTION_KEY": "Verschl\u00fcsselungsschl\u00fcssel \u00e4ndern",
      "ENCRYPTION_KEY": "Verschl\u00fcsselungsschl\u00fcssel",
      "ENCRYPTION_KEY_REPEAT": "Verschl\u00fcsselungsschl\u00fcssel wiederholen"
    },
    "MODAL_ARCHIVE": {
      "TITLE": "Chat archivieren",
      "TEXT": "M\u00f6chten Sie den Chat wirklich archivieren?"
    },
    "MODAL_CHANGE_STATUS": {
      "TITLE": "Aktive Chats",
      "TEXT": "Sie haben noch aktive Chats, möchten Sie trotzdem fortfahren?"
    },
    "USER_FORGOT_PIN": "Hinweis: Der Benutzer hat angegeben, dass er/sie den PIN-Code der App vergessen hat. Der Benutzer kann nicht mehr auf den Chat zugreifen und wird möglicherweise unter einem neuen Namen eintreten."
  },
  "AUTOMATIC_RESPONSE": {
    "TITLE": "Automatische Benachrichtigung",
    "SCHEDULED_TITLE": "Automatische Benachrichtigung zum Datenschutz",
    "GERMAN": "Deutsch",
    "ENGLISH": "Englisch",
    "DURING_WORKING_HOURS_TITLE": "Automatische Antwort au\u00dferhalb der Arbeitszeiten",
    "DURING_WORKING_DAYS": "An Arbeitstagen",
    "DURING_WEEKEND": "Am Wochenende",
    "DEFINE_WORKING_HOURS": "Arbeitszeiten definieren",
    "TIME_FROM": "Von",
    "TIME_TO": "Bis"
  },
  "LANGUAGE_NL": "Niederl\u00e4ndisch",
  "LANGUAGE_EN": "Englisch",
  "LANGUAGE_DE": "Deutsch",
  "LANGUAGE_FR": "Franz\u00f6sisch",
  "LANGUAGE_IT": "Italienisch",
  "LANGUAGE_ES": "Spanisch",
  "UNKNOWN": "Unbekannt",
  "INSIGHTS": {
    "CHOOSE_COMPANY": "Organisation w\u00e4hlen",
    "ALL_COMPANIES": "Alle Organisationen",
    "CHOOSE_DEPARTMENT": "Abteilung ausw\u00e4hlen",
    "ALL_DEPARTMENTS": "Alle Abteilungen",
    "DOWNLOAD_SCREENS_STATISTICS_CSV": "Screen Views herunterladen (.CSV)",
    "DOWNLOAD_ARTICLE_STATISTICS_CSV": "Artikelstatistiken herunterladen (.CSV)",
    "DOWNLOAD_USER_STATISTICS_XLSX": "Benutzerstatistiken herunterladen (.XLSX)",
    "GROUP_BY": "Gruppieren",
    "GROUP_BY_VALUES": {
      "PER_DAY": "Pro Tag",
      "PER_MONTH": "Pro Monat",
      "PER_YEAR": "Pro Jahr"
    },
    "CHOOSE_DATE_RANGE": "Zeitraum eingeben (optional)",
    "HEADERS": {
      "AGE": "Alter",
      "SEX": "Gender",
      "LANGUAGE": "Sprache",
      "LOGINS": "Logins",
      "SCREEN_VIEWS": "Schirmansichten",
      "USER_ACTIVES": "Benutzer aktiv"
    },
    "TABLE_HEADERS": {
      "DATE_SELECTION": "Datumsauswahl",
      "COMPANY_NAME": "Firmenname",
      "DEPARTMENT_NAME": "Abteilungsname",
      "SCREEN_NAME": "Schirmname",
      "ARTICLE_ID": "Artikel ID",
      "NUM": "Anzahl",
      "CODE": "Code",
      "TITLE_EN": "Titel (EN)",
      "TITLE_DE": "Titel (DE)",
      "TITLE_NL": "Titel (NL)"
    },
    "USER_STATISTICS": "Benutzerstatistiken",
    "SCREEN_STATISTICS": "Screen Statistiken",
    "USAGE_STATISTICS": "Nutzungsstatistiken",
    "ARTICLE_STATISTICS": "Artikelstatistiken",
    "NO_STATISTICS_WITH_TYPE": "Keine Statistiken verf\u00fcgbar f\u00fcr {{TYPE}} mit der aktuellen Auswahl."
  },
  "CHANGE_PASSWORD": {
    "TITLE": "Passwort \u00e4ndern",
    "CURRENT_PASSWORD": "Aktuelles Passwort",
    "NEW_PASSWORD": "Neues Passwort",
    "CONFIRM_PASSWORD": "Neues Passwort best\u00e4tigen",
    "WARNING_PGP": "Achtung: Wenn Sie Ihr Passwort ändern, generieren wir einen neuen PGP-Schlüssel, wodurch Sie keinen Zugriff mehr auf ältere Nachrichten haben.",
    "ERROR_MIN_LENGTH": "Das Passwort muss mindestens 8 Zeichen lang sein",
    "ERROR_LOWERCASE": "Das Passwort muss mindestens einen Kleinbuchstaben enthalten",
    "ERROR_UPPERCASE": "Das Passwort muss mindestens einen Großbuchstaben enthalten",
    "ERROR_DIGIT": "Das Passwort muss mindestens eine Ziffer enthalten",
    "ERROR_SPECIAL_CHAR": "Das Passwort muss mindestens ein Sonderzeichen enthalten (!@#$%^&*)",
    "PASSWORD_REQUIREMENTS": "Passwortanforderungen",
    "PASSWORD_MISMATCH": "Passwörter stimmen nicht überein",
    "PASSWORD_CHANGED": "Passwort erfolgreich geändert",
    "PASSWORD_INCORRECT": "Aktuelles Passwort ist falsch, Passwort wurde nicht geändert"
  },
  "SETTINGS": {
    "CHANGE_PASSWORD": "Passwort ändern"
  },
  "ARCHIVE": {
    "NO_ITEMS": "Es wurden noch keine Chats archiviert."
  }
}
