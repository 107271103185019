{
  "BUTTON_NEXT": "Weiter",
  "BUTTON_SEND": "Senden",
  "BUTTON_NEW_WORD": "Wort hinzuf\u00fcgen",
  "PRIVACY_POLICY": "der Datenschutzbestimmung",
  "TERMS": "den Nutzungsbedingungen",
  "REGISTER": {
    "TITLE": "F\u00fcr vertrauliche Angelegenheiten.",
    "TEXT": "Wenn Deine Organisation ein Abonnement hat, kannst Du hier Kontakt zu Deiner Vertrauensperson aufnehmen.",
    "QR_CODE": "Scanne den QR-Code",
    "TYPE_CODE": "Code eingeben?",
    "USE_CAMERA": "QR-Code scannen",
    "TYPE_CODE_PLACEHOLDER": "Code"
  },
  "INTRO_1": {
    "TEXT": "Wenn Sie mit unerw\u00fcnschtem Verhalten oder einem Integrit\u00e4tsproblem konfrontiert sind und dar\u00fcber sprechen m\u00f6chten oder ein offenes Ohr brauchen, k\u00f6nnen Sie sich an eine Vertrauensperson Ihrer Wahl wenden."
  },
  "INTRO_2": {
    "TEXT": "Bleiben Sie nicht daran h\u00e4ngen. Wir werden Sie immer ernst nehmen und gemeinsam mit Ihnen die m\u00f6glichen Optionen pr\u00fcfen. Das Gespr\u00e4ch ist vertraulich, es sei denn, es geht um eine Straftat, und kann anonym gef\u00fchrt werden."
  },
  "INTRO_3": {
    "TEXT": "Diese App ist sicher und f\u00fcr Dritte nicht zug\u00e4nglich. Nur Sie und die Vertrauensperson k\u00f6nnen die Nachrichten lesen. Die Vertrauensperson gibt niemals pers\u00f6nliche Informationen ohne Ihre Zustimmung weiter.",
    "I_AGREE_TERMS": "Ich stimme {{terms}} und {{privacy}} zu."
  },
  "HOME": {
    "TIPS": "Tipps f\u00fcr verschiedene Situationen",
    "INFO": "Vertrauensangelegenheiten bei {{COMPANY_NAME}}",
    "NEW_REPORT": "Ich will etwas loswerden",
    "CHAT": "Zum Chat",
    "CODE_INVALID_TEXT": "Der Code ist ung\u00fcltig. Bitte versuche es erneut.",
    "HOW_WE_WORK": "Wie wir arbeiten",
    "ABOUT_VP_NEDERLAND": "\u00dcber VP Nederland"
  },
  "NEW_REPORT": {
    "TITLE": "Ich habe etwas erlebt oder mitbekommen, was nun?",
    "INFO_POPUP_TEXT": "Deine Meldung wird streng vertraulich behandelt und nach 30 Tagen automatisch von unserem Server gel\u00f6scht. Wir speichern keine pers\u00f6nlichen Profile. Die Vertrauenspersonen k\u00f6nnen m\u00f6glicherweise Fragen zu Deiner Meldung stellen. Du kannst Dich daf\u00fcr entscheiden, anonym Kontakt aufzunehmen.",
    "REQUIRED_FIELD_USERNAME": "Erforderlich",
    "WORD_SELECT": "Welche Worte passen zu Deiner Meldung?",
    "REPORT": "Was ist passiert?",
    "FEELINGS": "Wie f\u00fchlst Du Dich?",
    "SITUATION_DESCRIPTION": "Beschreibe die Situation, die Du mitteilen m\u00f6chtest",
    "ABOUT_YOU": "\u00dcber Dich, sodass die Vertrauensperson einen Eindruck hat, von wem die Nachricht stammt",
    "ABOUT_YOU_INFO_POPUP_TEXT": "Der Nutzername ist verpflichtet, aber Du kannst jeden Namen und jedes Wort w\u00e4hlen, das du m\u00f6chtest. Die anderen Informationen sind freiwillig; Sie geben der Vertrauensperson mehr Details und werden anonymisiert f\u00fcr die Statistik verwendet.",
    "CONFIDANT_CONFIRM_TITLE": "Versende die Nachricht nun zur Vertrauensperson. Der Chat ist nicht f\u00fcr den akuten Notfall gedacht. Kontaktiere im Notfall die Hilfsdienste unter 112.",
    "CONFIDANT_CONFIRM": "Ich best\u00e4tige, dass ich {{terms}} und {{privacy}} zustimme ",
    "SEND": "Meldung versenden",
    "BY_SENDING_CONFIRM": "",
    "TYPE_ANONYMOUS_URGENT_TITLE": "Chatten",
    "TYPE_ANONYMOUS_URGENT": "Mich besch\u00e4ftigt eine unangenehme Situation und ich m\u00f6chte mit meiner Vertrauensperson besprechen, was die Handlungsoptionen f\u00fcr mich sind",
    "TYPE_ANONYMOUS_NOT_URGENT_TITLE": "Melden",
    "TYPE_ANONYMOUS_NOT_URGENT": "Ich m\u00f6chte eine Meldung t\u00e4tigen, sodass die Vertrauensperson wei\u00df, was passiert ist",
    "TYPE_CONFIDANT_CALL_TITLE": "Telefonieren",
    "TYPE_CONFIDANT_CALL": "Ich m\u00f6chte gerne telefonischen Kontakt mit meiner Vertrauensperson haben, um die Situation zu besprechen",
    "TYPE_PRIVACY": "Ich m\u00f6chte wissen, wie {{APP_NAME}} mit meinen Daten umgeht",
    "TYPE_VP_MAKE_APPOINTMENT_TITLE": "Termin vereinbaren",
    "TYPE_VP_MAKE_APPOINTMENT": "Ich m\u00f6chte einen Termin mit einer Vertrauensperson vereinbaren",
    "CONFIDANT_SELECT_TITLE": "W\u00e4hle deine Vertrauensperson",
    "CONFIDANT_SELECT_TEXT": "Beginnen Sie hier ein Gespr\u00e4ch mit einer Vertrauensperson. Es kann sein, dass Sie noch eine Antwort von einer anderen Person bekommen, weil sie besch\u00e4ftigt ist oder Urlaub hat.",
    "CONFIDANT_SELECT_NO_PREFERENCE": "Ich habe keine Pr\u00e4ferenz"
  },
  "CHAT": {
    "CHAT_CONSENT": "Im Notfall 112 kontaktieren oder telefonisch Kontakt aufnehmen mit anderen Hilfsdiensten. ",
    "I_UNDERSTAND": "Verstanden",
    "DECLINE": "Abbrechen",
    "CONSULTANT_TEAM": "Unsere Berater",
    "CHAT_INTRO": "Schick uns eine Nachricht, sodass wir dir weiterhelfen k\u00f6nnen.",
    "USER_HAS_UNSIGNED_CHATS": "Unser Chat ist zur Zeit nicht besetzt. Hinterlasse eine Nachricht, unsere Berater nehmen am kommenden Werktag Kontakt mit Ihnen auf. Im Notfall w\u00e4hle die 112.",
    "NOT_ASSIGNED": "Vertrauensperson",
    "NEW_CHAT": "Neuer Chat",
    "INPUT_PLACEHOLDER": "Schreibe hier deine Nachricht...",
    "INPUT_PLACEHOLDER_CONNECTING": "Eine sichere Verbindung wird hergestellt",
    "CURRENT_CHAT": "Aktuelles Gespr\u00e4ch",
    "YOU_ARE_SPEAKING_WITH": "Du sprichst mit"
  },
  "WORDS": {
    "REPORT": {
      "SCOLDING": "Ausschelten",
      "DISCRIMINATION": "Diskriminierung",
      "THREAT": "Bedrohung",
      "ASSAULT": "Misshandlung",
      "SEXUAL_VIOLENCE": "Sexuelle Gewalt",
      "SEXUAL_HARASSMENT": "Sexuelle Bel\u00e4stigung",
      "STALKING": "Stalking",
      "BULLYING": "Mobbing",
      "FRAUD": "Betrug",
      "CORRUPTION": "Korruption",
      "BLACKMAILING": "Erpressung",
      "RACISM": "Rassismus",
      "AGGRESSION": "Aggression",
      "HUMILIATION": "Erniedrigung",
      "THEFT": "Diebstahl",
      "INTIMIDATION": "Bel\u00e4stigung",
      "PLAGIARISM": "Plagiat"
    },
    "FEELINGS": {
      "SCARED": "\u00c4ngstlich",
      "ANGRY": "W\u00fctend",
      "STRESSED": "Gestresst",
      "EMBARRASSED": "Besch\u00e4mt",
      "LONELY": "Einsam",
      "INSECURE": "Verunsichert",
      "HUMILIATED": "Gedem\u00fctigt",
      "HURT": "Gekr\u00e4nkt",
      "OVERWHELMED": "\u00dcberfordert",
      "CONFUSED": "Verwirrt",
      "THREATENED": "Bedroht",
      "UNSAFE": "Unsicher",
      "FRUSTRATED": "Frustriert",
      "MISUNDERSTOOD": "Missverstanden",
      "DESPERATE": "Verzweifelt",
      "DEPRESSED": "Depressiv",
      "SHOCKED": "Schockiert"
    }
  },
  "CONFIDANTS_INFO": {
    "MENU_ITEM": "Die Vertrauenspersonen",
    "TITLE": "Vertrauenspersonen",
    "MAKE_APPOINTMENT": "Termin vereinbaren",
    "MAKE_APPOINTMENT_TITLE": "Termin vereinbaren",
    "SHOW_INTRODUCTION_VIDEO": "Einf\u00fchrungsvideo ansehen",
    "INTERNAL_VP": "Intern",
    "EXTERNAL_VP": "Extern"
  },
  "MAKE_APPOINTMENT": {
    "SELECTED_CONFIDANT": "Gew\u00e4hlte Vertrauensperson",
    "LEAVE_YOUR_DETAILS": "Hinterlasse deine Daten unten und wir werden uns mit dir in Verbindung setzen.",
    "FIRST_NAME": "Vorname",
    "LAST_NAME": "Nachname",
    "EMAIL": "E-Mail-Adresse (privat)",
    "PHONE": "Telefonnummer (privat)",
    "AGREEMENT": "Ich best\u00e4tige, dass ich den Nutzungsbedingungen und der Datenschutzbestimmung zustimme",
    "SEND": "Senden",
    "ASTERISKS": "** Mindestens eines der markierten Felder ist erforderlich",
    "ERROR_NAME": "Du musst sowohl deinen Vornamen als auch deinen Nachnamen eingeben",
    "ERROR_CONTACT": "Du musst eine g\u00fcltige E-Mail-Adresse oder Telefonnummer eingeben",
    "ERROR_AGREEMENT": "Du musst den Nutzungsbedingungen und der Datenschutzbestimmung zustimmen"
  }
}
