import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./core/guards/auth.guard";

const appRoutes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: "chat/:chatId",
    loadChildren: () => import("./chat/chat.module").then((m) => m.ChatModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "webchat",
    loadChildren: () =>
      import("./webchat/webchat.module").then((m) => m.WebchatModule),
    canActivate: [],
    runGuardsAndResolvers: "always",
  },
  {
    path: "webchat/:chatUuid",
    loadChildren: () =>
      import("./webchat/webchat.module").then((m) => m.WebchatModule),
    canActivate: [],
    runGuardsAndResolvers: "always",
  },
  {
    path: "archive",
    loadChildren: () =>
      import("./archive/archive.module").then((m) => m.ArchiveModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "**",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
