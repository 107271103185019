import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { CustomMaterialModule } from "./custom-material/custom-material.module";
import { AppRoutingModule } from "./app-routing.module";
import { LoggerModule } from "ngx-logger";
import { environment } from "../environments/environment";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTableModule } from "@angular/material/table";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { QuillModule } from "ngx-quill";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { PlotlyViaWindowModule } from "angular-plotly.js";
import { MatGridListModule } from "@angular/material/grid-list";
import { NgxMasonryModule } from "ngx-masonry";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
// @ts-ignore
import * as translationEN from "../assets/i18n/en.json";
// @ts-ignore
import * as translationDE from "../assets/i18n/de.json";
// @ts-ignore
import * as translationNL from "../assets/i18n/nl.json";
// @ts-ignore
import * as translationAppEN from "../assets/i18n_app/en.json";
// @ts-ignore
import * as translationAppDE from "../assets/i18n_app/de.json";
// @ts-ignore
import * as translationAppNL from "../assets/i18n_app/nl.json";
// @ts-ignore
import * as translationAppConfidantNL from "../assets/i18n_app/opgelucht/nl.json";
// @ts-ignore
import * as translationAppConfidantEN from "../assets/i18n_app/opgelucht/en.json";
// @ts-ignore
import * as translationAppConfidantDE from "../assets/i18n_app/opgelucht/de.json";

import { Observable, of } from "rxjs";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

const TRANSLATIONS = {
  en: {
    ...translationEN,
    ...{ APP: translationAppEN },
    ...{ CONFIDANT: translationAppConfidantEN },
  },
  de: {
    ...translationDE,
    ...{ APP: translationAppDE },
    ...{ CONFIDANT: translationAppConfidantDE },
  },
  nl: {
    ...translationNL,
    ...{ APP: translationAppNL },
    ...{ CONFIDANT: translationAppConfidantNL },
  },
};

const config: SocketIoConfig = {
  url: environment.socketUrl,
  options: { path: "/chat" },
};

export class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(TRANSLATIONS[lang]);
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SocketIoModule.forRoot(config),
    CoreModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatGridListModule,
    MatTableModule,
    MatSnackBarModule,
    NgxMasonryModule,
    CustomMaterialModule.forRoot(),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
      },
    }),
    QuillModule.forRoot({
      modules: {
        toolbar: [["bold", "italic", "underline", "strike"]],
      },
    }),
    PlotlyViaWindowModule,
    LoggerModule.forRoot({
      level: environment.logLevel,
      serverLogLevel: environment.serverLogLevel,
    }),
  ],
  exports: [],
  bootstrap: [AppComponent],
  providers: [
    provideAnimationsAsync()
  ],
})
export class AppModule {}
